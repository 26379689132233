exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-become-a-regional-champion-js": () => import("./../../../src/pages/become-a-regional-champion.js" /* webpackChunkName: "component---src-pages-become-a-regional-champion-js" */),
  "component---src-pages-big-stories-js": () => import("./../../../src/pages/big-stories.js" /* webpackChunkName: "component---src-pages-big-stories-js" */),
  "component---src-pages-get-involved-js": () => import("./../../../src/pages/get-involved.js" /* webpackChunkName: "component---src-pages-get-involved-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-participating-churches-js": () => import("./../../../src/pages/participating-churches.js" /* webpackChunkName: "component---src-pages-participating-churches-js" */),
  "component---src-pages-statement-of-faith-js": () => import("./../../../src/pages/statement-of-faith.js" /* webpackChunkName: "component---src-pages-statement-of-faith-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-the-big-give-agreement-js": () => import("./../../../src/pages/the-big-give-agreement.js" /* webpackChunkName: "component---src-pages-the-big-give-agreement-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}

